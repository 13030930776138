import { MeetingLeader, PrivateExternalParticipantCreate, RootState } from "../../../store";
import {
  fetchParticipantAutocompleteOptions, updateMeetingLeader,
  removeMeetingLeader, createMeetingParticipant, updateMeetingParticipant,
  deleteMeetingParticipant,
  addMeetingLeader,
} from "../../../store/schedule/actions";
import { useSelector } from "react-redux";
import { useWithDispatch } from "../../../store/hooks";
import { partial } from 'lodash-es';
import { selectLeadersWithCalendars, selectMeeting, selectMeetingLeaders } from "../../../store/schedule/selectors";
import EditParticipantsModal from "./EditParticipantsModal";
import { memo, useMemo, useState } from "react";
import { CabModal } from "@CabComponents/CabModal";
import { CabButton } from "@CabComponents/CabButton";


const _ = partial.placeholder;

export type EditParticipantsModalContainerProps = {
  open: boolean;
  onClose: () => void;
  meetingId: number;
};


const EditParticipantsModalContainer = ({ open, onClose, meetingId }: EditParticipantsModalContainerProps) => {
  const preventConflict = useSelector((root: RootState) => selectMeeting(root, meetingId)?.prevent_conflict);
  const leadersWithCalendars = useSelector((root: RootState) => selectLeadersWithCalendars(root));
  const meetingLeaders = useSelector((root: RootState) => selectMeetingLeaders(root, meetingId));
  const meetingParticipants = useSelector((root: RootState) => selectMeeting(root, meetingId)?.participants || {});
  const meetingIsPoll = useSelector((root: RootState) => selectMeeting(root, meetingId)?.is_poll || false);
  const participantAutoCompleteOptions = useSelector((root: RootState) => root.schedule.participantAutoCompletOptions);

  const isSavedMeeting = meetingId > -1;
  const participants = useMemo(() => Object.values(meetingParticipants), [meetingParticipants]);

  const handleFetchAutoCompleteOptions = useWithDispatch(fetchParticipantAutocompleteOptions);
  const handleAddLeader = useWithDispatch(partial(addMeetingLeader, meetingId));
  const handleUpdateLeader = useWithDispatch(partial(updateMeetingLeader, _, meetingId, _, isSavedMeeting));
  const handleRemoveLeader = useWithDispatch(partial(removeMeetingLeader, meetingId));

  const [deleteAttendeeId, setDeleteAttendeeId] = useState<number | null>(null);
  const [removeLeaderId, setRemoveLeaderId] = useState<number | null>(null);

  const addParticipant = (data: Omit<PrivateExternalParticipantCreate, 'meeting'>) => (
    createMeetingParticipant({ ...data, meeting: meetingId, required: true }, isSavedMeeting)
  );
  const handleAddParticipant = useWithDispatch(addParticipant);

  const handleUpdateParticipant = useWithDispatch(partial(updateMeetingParticipant, _, meetingId, isSavedMeeting));
  const handleRemoveParticipant = useWithDispatch(partial(deleteMeetingParticipant, _, meetingId, isSavedMeeting));

  const closeDeleteModal = () => {
    setDeleteAttendeeId(null);
    setRemoveLeaderId(null);
  };

  const handleRemoveMeetingLeader = (meetingLeaderId: MeetingLeader['id']) => {
    closeDeleteModal();
    handleRemoveLeader(meetingLeaderId);
  };

  const handleDeleteAttendee = async (attendeeId: number) => {
    closeDeleteModal();
    handleRemoveParticipant(attendeeId);
  };

  return (
    <>
      <EditParticipantsModal
        open={open}
        onClose={onClose}
        meetingIsSaved={isSavedMeeting}
        participants={participants}
        meetingLeaders={meetingLeaders}
        addLeaderOptions={leadersWithCalendars}
        participantAutoCompleteOptions={participantAutoCompleteOptions}
        meetingPreventConflicts={preventConflict}
        onAddLeader={handleAddLeader}
        onUpdateLeader={handleUpdateLeader}
        onRemoveLeader={setRemoveLeaderId}
        onAddParticipant={handleAddParticipant}
        onUpdateParticipant={handleUpdateParticipant}
        onDeleteParticipant={setDeleteAttendeeId}
        onFetchAutocompleteOptions={handleFetchAutoCompleteOptions}
      />
      {(!!deleteAttendeeId || !!removeLeaderId) && (
        <CabModal
          open={!!deleteAttendeeId || !!removeLeaderId}
          title={'Remove Attendee'}
          onClose={closeDeleteModal}
          isAlert={true}
          noFullScreen={true}
          text={meetingIsPoll 
            ? 'All votes for times that this person submitted will be removed.' 
            : 'Are you sure you want to remove this person from the meeting?'}
          actionButtons={
            <>
              <CabButton buttonType='tertiary' color='primary' onClick={closeDeleteModal}>
                Cancel
              </CabButton>
              <CabButton onClick={ deleteAttendeeId ? () => handleDeleteAttendee(deleteAttendeeId)
                : removeLeaderId ? () => handleRemoveMeetingLeader(removeLeaderId) : undefined} 
              color="error"
              >
                Delete
              </CabButton>
            </>
          }
        />
      )}
    </>
  );
};

export default memo(EditParticipantsModalContainer);
