import { CabButton } from "@CabComponents/CabButton";
import { CabModal } from "@CabComponents/CabModal";
import { ReactElement } from "react";
import AttendeeList from "../ScheduleShareModal/AttendeeList";
import {
  Leader, MeetingLeader, MeetingLeaderUpdate, ParticipantAutocompleteOption,
  PrivateExternalParticipant, PrivateExternalParticipantCreate, PrivateExternalParticipantUpdate,
} from "../../../store";


export type EditParticipantsModalProps = {
  open: boolean;
  onClose: () => void;
  meetingIsSaved: boolean;
  participants?: PrivateExternalParticipant[];
  meetingLeaders: MeetingLeader[];
  onAddLeader: (id: number) => void;
  onUpdateLeader: (leader: MeetingLeaderUpdate, leaderId: number) => Promise<void>;
  onRemoveLeader: (meetingLeaderId: number) => void;
  addLeaderOptions: Leader[];
  participantAutoCompleteOptions: ParticipantAutocompleteOption[];
  onAddParticipant: (participant: Omit<PrivateExternalParticipantCreate, 'meeting'>) => Promise<void>;
  onUpdateParticipant: (participant: PrivateExternalParticipantUpdate) => Promise<void>;
  onDeleteParticipant: (id: number) => void;
  onFetchAutocompleteOptions: (s: string) => Promise<void>;
  meetingPreventConflicts?: boolean;
};



const EditParticipantsModal = ({ 
  open, onClose, meetingIsSaved, participants, meetingLeaders, participantAutoCompleteOptions,
  onRemoveLeader, onAddLeader, onUpdateLeader, addLeaderOptions, meetingPreventConflicts,
  onAddParticipant, onDeleteParticipant, onUpdateParticipant, onFetchAutocompleteOptions,
}: EditParticipantsModalProps): ReactElement => {
  return (
    <CabModal
      open={open}
      onClose={onClose}
      title="People & Calendars"
      closeIcon
      closeOnBackdropClick
      actionButtons={
        <CabButton buttonType="primary" onClick={() => onClose()}>
          Done
        </CabButton>
      }
      sx={{
        '& .MuiDialog-paper': {
          width: '100%',
          maxWidth: 705,
        },
      }}
    >
      <AttendeeList
        attendees={participants || []}
        onDeleteAttendee={onDeleteParticipant}
        onAddAttendee={onAddParticipant}
        onUpdateAttendee={onUpdateParticipant}
        participantAutocompleteOptions={participantAutoCompleteOptions}
        fetchAutocompleteOptions={onFetchAutocompleteOptions}
        isMdDown={false}
        hasAttendeeListOptions={true}
        meetingLeaders={meetingLeaders}
        onRemoveLeader={onRemoveLeader}
        onAddLeader={onAddLeader}
        onUpdateLeader={onUpdateLeader}
        leaders={addLeaderOptions}
        isAdditionalParticipantModal
        // disableAdditionalParticipantsMessage={meetingIsSaved
        //   ? undefined
        //   : 'Additional participants can be added once the meeting has been saved'}
        meetingPreventConflicts={meetingPreventConflicts}
        meetingIsSaved={meetingIsSaved}
      />
    </CabModal>
  );
};

export default EditParticipantsModal;
